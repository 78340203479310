export enum PATHS {
  main = '/',
  courses = '/courses',

  privacy = 'https://teachmeskills.ru/politika-obrabotki-personalnyh-dannyh',
  vk = 'https://vk.com/teachmeskills',
  telegram = 'https://t.me/TMS_news',
  youtube = 'https://www.youtube.com/channel/UCrpbZ8VZjn8FtMv0-I1VJ2A',
  teachers = 'https://teachmeskills.ru/teachers',
  blog = 'https://teachmeskills.ru/blog',
}
