import * as React from 'react';
import { memo } from 'react';

import styled from 'styled-components';

import { ColorService, desktop, getFontFamily, tablet } from 'services';
import { Icon, IconsNames } from 'components/atoms';
import { Link } from 'react-router-dom';
import { CategoryNames } from 'context';

export interface ICourseCard {
  categoryName: CategoryNames;
  isHit?: boolean;
  course: string | React.ReactNode;
  discountPrice: string;
  oldPrice: string;
  img: IconsNames;
  link: string;
  className?: string;
}

export const CourseCard = memo(
  ({
    categoryName,
    course,
    isHit,
    discountPrice,
    oldPrice,
    img,
    link,
    className,
  }: ICourseCard) => (
    <Wrapper className={className}>
      <Link to={link}>
        <Unit>
          {categoryName}
          {isHit && (
            <>
              {' '}
              • <HitText>хит продаж</HitText>
            </>
          )}
        </Unit>
        <CourseName>{course}</CourseName>
        <PriceBlock>
          <DiscountPrice>
            {discountPrice}
            {isHit && <Icon name={IconsNames.fire} width={20} height={20} />}
          </DiscountPrice>
          <OldPrice>{oldPrice}</OldPrice>
        </PriceBlock>
        <ImgCourse name={img} />
      </Link>
    </Wrapper>
  ),
);

const Wrapper = styled.div`
  padding: 40px 32px;
  background: ${ColorService.WHITE};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  transition: 0.6s;
  position: relative;
  min-height: 242px;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 20px rgba(100, 100, 100, 0.4);
  }

  ${tablet} {
    padding: 40px 32px 40px 40px;
  }

  ${desktop} {
    padding: 40px;
  }
`;

const Unit = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${ColorService.GRAY};
`;

const HitText = styled.span`
  color: ${ColorService.RED};
`;

const CourseName = styled.p`
  font-family: ${getFontFamily('bold')};
  font-size: 18px;
  line-height: 28px;
  color: ${ColorService.MAIN_BLACK};
  margin: 8px 0 44px;
  height: 44px;
  z-index: 1;

  ${desktop} {
    margin: 8px 0 60px;
  }
`;

const PriceBlock = styled.p`
  margin-top: auto;
  z-index: 1;
`;

const DiscountPrice = styled.p`
  font-family: ${getFontFamily('medium')};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${ColorService.MAIN_BLACK};
`;

const OldPrice = styled.p`
  text-decoration-line: line-through;
  font-size: 14px;
  line-height: 24px;
  color: ${ColorService.GRAY};
`;

const ImgCourse = styled(Icon)`
  height: 50%;
  width: auto;
  object-fit: contain;
  object-position: right;
  align-self: flex-end;
  position: absolute;
  right: -16px;
  bottom: 0;
`;
