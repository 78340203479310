import React, { memo, useRef } from 'react';

import styled from 'styled-components';

import { LinkArrow, GiftBlock, SaleOfferCard } from 'components/atoms';
import { MainForm, Hits } from 'components/molecules';

import {
  ColorService,
  desktop,
  getFontFamily,
  laptop_small,
  tablet,
} from 'services';
import { PATHS } from 'router/config';

export const Main = memo(() => {
  const formRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Content>
        <MainInfoWrapper>
          <Title>Большая летняя распродажа</Title>
          <Subtitle>Запишись на курсы июля со скидкой 15%</Subtitle>
          <Warning>Количество мест ограничено!</Warning>
          <GiftBlock />
        </MainInfoWrapper>

        <ContentForm>
          <MainForm />
        </ContentForm>

        <SaleOfferCard />

        <HitCourses>
          <HitCoursesHead>
            <SecondaryTitle>Хиты продаж</SecondaryTitle>
            <LinkArrow
              title="Смотреть все"
              link={`${PATHS.courses}?filter=hits`}
            />
          </HitCoursesHead>

          <Hits />
        </HitCourses>
      </Content>
      <FormWrapper ref={formRef}>
        <MainForm />
      </FormWrapper>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  ${desktop} {
    flex-direction: row;
    gap: 54px;
  }
`;

const Content = styled.div``;

const MainInfoWrapper = styled.div`
  margin: 0 0 64px;

  ${tablet} {
    margin: 0 0 80px;
  }
`;

const Title = styled.h1`
  font-family: ${getFontFamily('bold')};
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 8px;

  ${tablet} {
    font-size: 64px;
    line-height: 76px;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.h2`
  font-family: ${getFontFamily('bold')};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;

  ${tablet} {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 32px;
  }
`;

const SecondaryTitle = styled.h3`
  width: 68%;
  font-family: ${getFontFamily('bold')};
  font-size: 24px;
  line-height: 32px;

  ${tablet} {
    width: 100%;
    font-size: 32px;
    line-height: 44px;
  }
`;

const Warning = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 64px;
  padding: 8px 16px;
  border-radius: 500px;
  background-color: ${ColorService.YELLOW};
  font-family: ${getFontFamily('bold')};
  width: max-content;
  text-transform: uppercase;

  ${tablet} {
    margin-bottom: 80px;
  }

  ${laptop_small} {
    margin-bottom: 64px;
  }
`;

const FormWrapper = styled.div`
  display: none;

  ${desktop} {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    align-self: flex-start;
    flex-direction: column;
    gap: 12px;
    top: 54px;
  }
`;

const ContentForm = styled.div`
  margin-bottom: 64px;

  ${tablet} {
    margin-bottom: 80px;
  }

  ${desktop} {
    display: none;
    margin-bottom: 0;
  }
`;

const HitCourses = styled.div``;

const HitCoursesHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;

  ${tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
`;
