import React, { memo, useEffect, useState } from 'react';

import styled from 'styled-components';

import { CourseCard } from 'components/atoms';
import { desktop, tablet } from 'services';
import { CategoryNames, AllCourses, ICourse, HitCourses } from 'context';

interface ICatalogProps {
  activeCategory: CategoryNames;
}

export const Catalog = memo(({ activeCategory }: ICatalogProps) => {
  const [filteredCourses, setFilteredCourses] = useState<ICourse[]>(AllCourses);
  const [sortedCourses, setSortedCourses] = useState<ICourse[]>([]);

  useEffect(() => {
    setFilteredCourses(() => {
      if (activeCategory === CategoryNames.hits) {
        return AllCourses.filter(({ key }) => HitCourses.includes(key));
      }

      if (activeCategory === CategoryNames.all) {
        return AllCourses;
      }

      return AllCourses.filter(
        (course) => course.categoryName === activeCategory,
      );
    });
  }, [activeCategory]);

  useEffect(() => {
    setSortedCourses([
      ...filteredCourses.filter(({ key }) => HitCourses.includes(key)),
      ...filteredCourses.filter(({ key }) => !HitCourses.includes(key)),
    ]);
  }, [filteredCourses]);

  return (
    <Wrapper>
      {sortedCourses.map(
        ({
          key,
          courseName,
          categoryName,
          discountPrice,
          oldPrice,
          img,
          link,
        }) => (
          <StyledCard
            key={key}
            categoryName={categoryName}
            course={courseName}
            discountPrice={discountPrice}
            isHit={HitCourses.includes(key)}
            oldPrice={oldPrice}
            img={img}
            link={link}
          />
        ),
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  ${tablet} {
    gap: 24px;
  }
`;

const StyledCard = styled(CourseCard)`
  flex-basis: 100%;
  box-sizing: border-box;
  transition: 0.6s;

  ${tablet} {
    flex-basis: calc(50% - 14px);
  }

  ${desktop} {
    flex-basis: calc(33% - 14px);
  }
`;
