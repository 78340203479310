import React, { memo } from 'react';

import styled from 'styled-components';

import { ColorService, desktop, tablet } from 'services';
import { Counter } from 'components/atoms';
import { ContactForm } from 'components/molecules';
import {
  Box,
  ErrorText,
  Form,
  FormText,
  Hint,
  PhoneInputWrapper,
  StyledButton,
  StyledErrorForm,
  StyledInput,
  StyledInputMask,
} from 'components/molecules/ContactForm/ContactForm';

interface IMainFormProps {
  className?: string;
}

export const MainForm = memo(({ className }: IMainFormProps) => (
  <FormWrapper className={className}>
    <CounterStyled />

    <ContactForm
      titleBtn="Получить скидку"
      text="Мы свяжемся в течение дня, бесплатно проконсультируем и поможем с выбором направления."
    />
  </FormWrapper>
));

const FormWrapper = styled.form`
  position: relative;
  background-color: ${ColorService.MAIN_BLACK};
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px 32px;
  border: 1px solid ${ColorService.SILVER};
  margin: 64px -16px 0;

  ${tablet} {
    margin: 0;
    padding: 24px 40px 32px;
  }

  ${desktop} {
    width: 356px;
  }

  ${Box} {
    padding: 0;
  }

  ${Form} {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  ${Hint} {
    display: none;
  }

  ${FormText} {
    display: block;
    text-align: center;
  }

  ${PhoneInputWrapper} {
    margin-bottom: 24px;
  }

  ${StyledInput} {
    width: 100%;
  }

  ${StyledInputMask} {
    width: 100%;
  }

  ${StyledButton} {
    width: 100%;
    margin-bottom: 24px;
  }

  ${ErrorText} {
    max-width: 360px;
  }

  ${StyledErrorForm} {
    margin-top: -22px;
    text-align: center;
  }
`;

const CounterStyled = styled(Counter)`
  width: 100%;
  height: 64px;
  margin-bottom: 24px;
`;
