import React, { memo, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

import { CategoryTab, Icon, IconsNames } from 'components/atoms';
import { Catalog, ConsultationForm } from 'components/molecules';
import { desktop, getFontFamily, tablet } from 'services';
import { CategoryNames } from 'context';

interface ICategoriesTabs {
  categoryName: CategoryNames;
  tabTitle: React.ReactNode;
  categoryKey?: keyof typeof CategoryNames;
}

const TabWithIconWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CategoriesTabs: ICategoriesTabs[] = Object.values(CategoryNames).map(
  (category, index) => {
    if (category === CategoryNames.hits) {
      return {
        categoryKey: Object.keys(CategoryNames)[
          index
        ] as keyof typeof CategoryNames,
        categoryName: category,
        tabTitle: (
          <TabWithIconWrapper>
            <Icon name={IconsNames.fire} width={20} height={20} /> {category}
          </TabWithIconWrapper>
        ),
      };
    }

    return {
      categoryName: category,
      tabTitle: category,

      categoryKey: Object.keys(CategoryNames)[
        index
      ] as keyof typeof CategoryNames,
    };
  },
);

export const Courses = memo(() => {
  const [activeTab, setActiveTab] = useState<CategoryNames>(CategoryNames.all);

  const [tabParam, setTabParam] = useSearchParams();

  useEffect(() => {
    const tab = tabParam.get('filter');
    if (tab && CategoryNames[tab as keyof typeof CategoryNames]) {
      setActiveTab(CategoryNames[tab as keyof typeof CategoryNames]);
    }
  }, [tabParam]);

  const changeFilter = (
    tabName: CategoryNames,
    newTabParam?: keyof typeof CategoryNames,
  ) => {
    setActiveTab(tabName);
    if (newTabParam) {
      setTabParam({ filter: newTabParam });
    }
  };

  return (
    <Container>
      <Content>
        <MainInfoWrapper>
          <Title>Каталог курсов</Title>
          <Subtitle>Никаких записей курсов — только живое общение</Subtitle>
        </MainInfoWrapper>

        <CategoriesBox>
          <Categories>
            {CategoriesTabs?.map(({ categoryName, tabTitle, categoryKey }) => (
              <Category key={categoryName}>
                <CategoryTab
                  title={tabTitle}
                  isActive={activeTab === categoryName}
                  onClick={() => changeFilter(categoryName, categoryKey)}
                />
              </Category>
            ))}
          </Categories>
        </CategoriesBox>

        <Catalog activeCategory={activeTab} />

        <ConsultationForm />
      </Content>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div``;

const MainInfoWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

const Title = styled.h1`
  font-family: ${getFontFamily('bold')};
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 8px;

  ${tablet} {
    font-size: 48px;
    line-height: 62px;
  }

  ${desktop} {
    font-size: 54px;
    line-height: 62px;
  }
`;

const Subtitle = styled.p`
  font-size: 12px;
  line-height: 18px;

  ${tablet} {
    font-size: 24px;
    line-height: 36px;
  }
`;

const CategoriesBox = styled.div`
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  margin-bottom: 40px;
  width: calc(100% + 20px);

  ${tablet} {
    overflow-y: initial;
    max-width: 800px;
    margin: 0 auto 64px;
    width: auto;
  }

  ${desktop} {
    max-width: 956px;
  }
`;

const Categories = styled.div`
  display: flex;
  margin: 8px 0;

  ${tablet} {
    flex-wrap: wrap;
    justify-content: center;
  }

  ${desktop} {
    margin: 24px 0 16px;
    justify-content: center;
  }
`;

const Category = styled.div`
  margin: 8px 4px;

  &:first-of-type {
    margin: 8px 4px 8px 0;
  }
`;
